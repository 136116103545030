import React, { useEffect } from 'react';
import Image1 from '../assets/Productos.jfif';
import WhatsappButtonFlowers from '../components/WhatsappButtonFlowers.js';
import './WhatsappButtonFlowers.css';
import './FlowersProductos.css'; // Asegúrate de importar tu archivo CSS
import Flowers1 from '../assets/ImagenesProductos/Flowers1.PNG';
import Flowers2 from '../assets/ImagenesProductos/Flowers2.PNG';
import Flowers3 from '../assets/ImagenesProductos/Flowers3.PNG';
import Flowers4 from '../assets/ImagenesProductos/Flowers4.PNG';
import Flowers5 from '../assets/ImagenesProductos/Flowers5.PNG';
import Flowers6 from '../assets/ImagenesProductos/Flowers6.PNG';
import Flowers7 from '../assets/ImagenesProductos/Flowers7.PNG';
import Flowers8 from '../assets/ImagenesProductos/Flowers8.PNG';
import Flowers9 from '../assets/ImagenesProductos/Flowers9.PNG';
import Flowers10 from '../assets/ImagenesProductos/Flowers10.PNG';
import Flowers11 from '../assets/ImagenesProductos/Flowers11.PNG';
import Flowers12 from '../assets/ImagenesProductos/Flowers12.PNG';
import Flowers13 from '../assets/ImagenesProductos/Flowers13.PNG';
import Flowers14 from '../assets/ImagenesProductos/Flowers14.PNG';
import Flowers15 from '../assets/ImagenesProductos/Flowers15.PNG';
import Flowers16 from '../assets/ImagenesProductos/Flowers16.PNG';



function Productos() {
  const bgColors = [
    'from-pink-550 ', // De un rosado más intenso a verde claro
   
  ];
  return (



   

    <div className="flex flex-col items-center text-center bg-gradient-to-r from-white to-pink-200 transition-colors duration-700 w-full">
      <div className="text-center mb-8">
        <h2 className="text-5xl font-serif font-bold text-pink-500">Productos</h2>
        <p className="font-serif text-center text-black text-2xl w-full md:w-3/4 mx-auto mt-4">
          Regala un toque de alegría y amor con nuestros hermosos ramos de flores.
          Perfectos para cualquier ocasión. ¡Entrega rápida y garantizada!
        </p>
      </div>
  
      <div className="flex flex-wrap justify-center gap-[3cm]"> {/* Ajusta el espaciado aquí */}
        {/* Primer bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers1} className="ImagenFlowers1   rounded-md mb-4" alt="Flower 1" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Rosas y girasoles</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">280.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>
  
        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers2} className="ImagenFlowers2   rounded-md mb-4" alt="Flower 2" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black ">Ramo exotico y peluche</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">210.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>



{/* Primer bloque de imagen y texto */}
<div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers3} className="ImagenFlowers3   rounded-md mb-4" alt="Flower 3" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Media luna de rosas</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">190.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>
  
        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers4} className="ImagenFlowers4   rounded-md mb-4" alt="Flower 4" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Ramos en rosas bouquet</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">130.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>




{/* Primer bloque de imagen y texto */}
<div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers5} className="ImagenFlowers5   rounded-md mb-4" alt="Flower 5" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Hortencias y rosas</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">100.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>
  
        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers6} className="ImagenFlowers6   rounded-md mb-4" alt="Flower 6" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Lirios y rosas</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">250.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>








{/* Primer bloque de imagen y texto */}
<div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers7} className="ImagenFlowers7   rounded-md mb-4" alt="Flower 7" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Amanecer floral </p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">140.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>
  
        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers8} className="ImagenFlowers8   rounded-md mb-4" alt="Flower 8" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Encanto rosado</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">300.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>


{/* Segundo bloque de imagen y texto */}
<div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers9} className="ImagenFlowers9   rounded-md mb-4" alt="Flower 9" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Amor soleado</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">175.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
                 
      
          </div>
     
        </div>



      


{/* Primer bloque de imagen y texto */}
<div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers10} className="ImagenFlowers10   rounded-md mb-4" alt="Flower 10" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Corazon de recuerdo</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">550.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>
  
        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers11} className="ImagenFlowers11   rounded-md mb-4" alt="Flower 11" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Ronda de Recuerdo</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">190.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>








{/* Primer bloque de imagen y texto */}
<div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers12} className="ImagenFlowers12   rounded-md mb-4" alt="Flower 12" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Amanecer sereno</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">250.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>
  
        {/* Segundo bloque de imagen y texto */}
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers13} className="ImagenFlowers13   rounded-md mb-4" alt="Flower 13" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Blanco Infinito</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">380.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>


{/* Segundo bloque de imagen y texto */}
<div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers14} className="ImagenFlowers14   rounded-md mb-4" alt="Flower 14" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Memorias de oro</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">160.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
                 
      
          </div>
     
        </div>



{/* Segundo bloque de imagen y texto */}
<div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers15} className="ImagenFlowers15   rounded-md mb-4" alt="Flower 15" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Reflejo de amor</p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">320.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>
          </div>
        </div>


{/* Segundo bloque de imagen y texto */}
   <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <img src={Flowers16} className="ImagenFlowers16   rounded-md mb-4" alt="Flower 16" />
          <div className="flex flex-col items-start">
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Nombre:</h3>
              <p className="text-black">Recuerdos eternos </p>
            </div>
            <div className="flex items-center space-x-4 mb-2">
              <h3 className="text-lg font-bold text-black">Precio:</h3>
              <p className="text-black">210.000</p>
            </div>
            <a href="#">
              <WhatsappButtonFlowers />
            </a>

                 
      
          </div>
     
        </div>


      </div>



      </div>



  );
}

export default Productos;
