// src/components/FacebookButton.js
import React from 'react';
import './CorreoButton.css';

const CorreoButton = () => {
  const correoLink = "mailto:lilianahernandez1820@gmail.com"; // Reemplaza con tu enlace de Facebook

  return (
    <div className="container">
           
    <a href={correoLink} target="_blank"  className="correo-button">
      <img src="/icons8-gmail-94.png" alt="Correo" className="icons8-gmail-94" />
      Correo
    </a>
    </div>
  );
};

export default CorreoButton;
