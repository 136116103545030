// src/components/FacebookButton.js
import React from 'react';
import './WhatsappButtonFlowers.css';

const WhatsappButtonFlowers = () => {
  const WhatsappButtonFlowersLink = "https://wa.me/573226473899";

  return (
    <div className="container">
           
    <a href={WhatsappButtonFlowersLink} target="_blank"  className="WhatsappButtonFlowers-button">
      <img src="/3d-fluency-whatsapp-logo2.png" alt="WhatsappButtonFlowers2" className="fluency-whatsapp-logo2" />
     Solicitar
    </a>
    </div>
  );
};

export default WhatsappButtonFlowers;


