import React, { useState } from 'react'; 
import Home from './components/Home';
import Nosotros from './components/Nosotros';
import Servicios from './components/Servicios';
import Productos from './components/Productos';
import image from './assets/LogoFloristeria.enc'
import 'tailwindcss/tailwind.css';
import './App.css';

function App() {
  const [selectedOption, setSelectedOption] = useState('home');

  const handleMenuClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="min-h-screen bg-gray-100">
     <nav className="bg-white shadow-md">
  <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6">
    <div className="relative flex items-center justify-between h-24">
      <div className="flex-shrink-0 flex items-center">
        <img src={image} alt="Logo" className="h-16 w-auto object-cover"/>
        <h1 className="text-2xl font-serif font-light text-pink-500 ml-4">Floristería</h1>
      </div>
      <div className="hidden sm:flex sm:space-x-4">
        <a className="text-pink-500 hover:text-pink px-3 py-2 rounded-md text-lg cursor-pointer" onClick={() => handleMenuClick('home')}>Inicio</a>
        <a className="text-pink-500 hover:text-pink px-3 py-2 rounded-md text-lg cursor-pointer" onClick={() => handleMenuClick('about')}>Nosotros</a>
        <a className="text-pink-500 hover:text-pink px-3 py-2 rounded-md text-lg cursor-pointer" onClick={() => handleMenuClick('services')}>Servicios</a>
        <a className="text-pink-500 hover:text-pink px-3 py-2 rounded-md text-lg cursor-pointer" onClick={() => handleMenuClick('products')}>Productos</a>
      </div>
      <div className="sm:hidden">
        <button className="font-serif text-pink-500 px-5 py-2 rounded-md text-lg" onClick={() => setSelectedOption('menu')}>Menu</button>
      </div>
    </div>
  </div>
  {selectedOption === 'menu' && (
    <div className="sm:hidden px-2 pt-2 pb-3 space-y-1">
      <a className="text-pink-500 hover:bg-pink-400 hover:text-pink block px-3 py-2 rounded-md text-base font-medium cursor-pointer" onClick={() => handleMenuClick('home')}>Inicio</a>
      <a className="text-pink-500 hover:bg-pink-400 hover:text-pink block px-3 py-2 rounded-md text-base font-medium cursor-pointer" onClick={() => handleMenuClick('about')}>Nosotros</a>
      <a className="text-pink-500 hover:bg-pink-400 hover:text-pink block px-3 py-2 rounded-md text-base font-medium cursor-pointer" onClick={() => handleMenuClick('services')}>Servicios</a>
      <a className="text-pink-500 hover:bg-pink-400 hover:text-pink block px-3 py-2 rounded-md text-base font-medium cursor-pointer" onClick={() => handleMenuClick('products')}>Productos</a>
    </div>
  )}
</nav>

      <div className="max-w-7xl mx-auto px-4 py-8">
        {selectedOption === 'home' && <Home />}
        {selectedOption === 'about' && <Nosotros />}
        {selectedOption === 'services' && <Servicios />}
        {selectedOption === 'products' && <Productos />}
      </div>
    </div>
  );
}

export default App;

