import Carrusel from "./Carrusel";
import Presentacion from "./Presentacion"; 
function Home() {
    return ( 
      <>  
      <Presentacion/> 

      <div className={`bg-gradient-to-r from-indigo-500 to-sky-500`}>
        <Carrusel />
      </div>
      </>
  
    );
  }
  
  export default Home;
  