import React, { useState, useEffect } from 'react';
import Florpresentacion1 from './Flores.js';

function Presentacion() {
  const [bgColorIndex, setBgColorIndex] = useState(0);

  const bgColors = [
    'from-pink-50 to-pink-300', // De un rosado mucho más claro a un rosado un poco más oscuro
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setBgColorIndex((prevIndex) => (prevIndex + 1) % bgColors.length);
    }, 3000); // Cambia de color cada 3 segundos

    return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
  }, []);

  // Cargar Google Tag Manager/Analytics
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16451490837";
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16451490837');
    `;
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(script2);
    };
  }, []);

  const textClass = "text-green-700 font-semibold drop-shadow-lg";

  return (
    <div className={`flex flex-col items-center text-center bg-gradient-to-r ${bgColors[bgColorIndex]} transition-colors duration-700 w-full`} style={{ minHeight: '7cm' }}>
      <div className="flex flex-col items-center justify-start text-center w-full h-full p-4">
        <h2 className={`text-4xl sm:text-5xl font-bold font-serif ${textClass}`} id="headingOne" >
          El Jardín De Lily - Floristería
        </h2>
        <p className={`text-xl sm:text-2xl ${textClass} mt-4`}>
          Donde puedes llevar momentos de Felicidad con hermosos ramos de Flores
        </p>
        <p className={`font-serif text-5xl sm:text-7xl ${textClass} mt-4`}>
          ¡Amor en cada Flor!
        </p>
      </div>
    </div>
  );
}

export default Presentacion;
