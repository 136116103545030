// src/App.js
import React from 'react';
import './ImagenAgua.css';    // Importar los estilos CSS
import FacebookButton from '../components/FacebookButton.js';
import InstagramButton from '../components/InstagramButton.js';


function Nosotros() {
  return (
    <div className ="Container_">
    <div className="flex flex-col items-center text-center bg-gradient-to-r from-white to-pink-200 transition-colors duration-700 w-full">
      <div className="image-container">
      
        <div className="text-overlay">
          <h2 className="font-serif text-5xl font-bold text-pink-500 text-opacity-80">Misión</h2>
          <p className="font-serif text-center text-2xl text-gray-700 w-full md:w-3/4 mx-auto mt-4">
 
          Brindar alegría y belleza a nuestros clientes a través de
          flores frescas y arreglos florales únicos, ofreciendo un
          servicio excepcional y personalizado en cada visita.

    
          </p>
          <br />
          <h2 className="font-serif text-5xl font-bold text-pink-500 text-opacity-80">Visión</h2>
          <p className=" font-serif text-center text-2xl text-gray-700 w-full md:w-3/4 mx-auto mt-4">
          
          Nuestra visión es convertirnos en una floristería reconocida y respetada, enfocándonos en lograr ser y no competir, 
          dirigiéndonos constantemente hacia la excelencia en cada aspecto de nuestro negocio.
          </p><br/>
          <div className="buttons flex justify-center gap-4">
           
            <a href="#" className="inline-block">
              <InstagramButton />
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Nosotros;
