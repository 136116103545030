import React from 'react';
import WhatsappButton from '../components/WhatsappButton';
import CorreoButton from '../components/CorreoButton';

function Servicios() {
  return (
    <div className="flex flex-col items-center text-center bg-gradient-to-r from-white to-pink-200 transition-colors duration-700 w-full">
      <section className="text-center mb-8 w-full max-w-4xl">
        <h2 className="font-serif text-5xl font-bold text-pink-500 mb-4">
          Servicios
        </h2>
        <p className="font-serif 
      text-center text-3xl text-gray-700 mx-auto mt-1">
          En nuestra floristería creamos ramos y decoraciones únicas para llenar de belleza cualquier ocasión especial. ¡Déjanos ser parte de tus momentos más significativos! Nuestros servicios son:
        </p>
      </section>

      <section className="mb-8 w-full max-w-4xl mx-auto">
        <div className="flex flex-col md:flex-row gap-4 justify-center items-center">
          <div className="rounded-md border shadow-md p-4 flex-1 min-h-[250px] flex flex-col justify-between">
            <h3 className="text-2xl font-bold text-gray-700">Ramos de Flores Frescas</h3>
            <p className="text-gray-600">
              Gran variedad de flores frescas disponibles diariamente. Composiciones para todas las ocasiones.
            </p>
          </div>

          <div className="rounded-md border shadow-md p-4 flex-1 min-h-[250px] flex flex-col justify-between">
            <h3 className="text-2xl font-bold text-gray-700">Decoración para Eventos</h3>
            <p className="text-gray-600">
              Decoración floral para bodas, cumpleaños y eventos corporativos. Paquetes especiales disponibles.
            </p>
          </div>

          <div className="rounded-md border shadow-md p-4 flex-1 min-h-[250px] flex flex-col justify-between">
            <h3 className="text-2xl font-bold text-gray-700">Plantas y Suculentas</h3>
            <p className="text-gray-600">
              Amplia selección de plantas y suculentas para el hogar y la oficina. Asesoramiento en el cuidado de plantas.
            </p>
          </div>

          <div className="rounded-md border shadow-md p-4 flex-1 min-h-[250px] flex flex-col justify-between">
            <h3 className="text-2xl font-bold text-gray-700">Servicio de Entrega a Domicilio</h3>
            <p className="text-gray-600">
              Entrega rápida y segura en toda Barranquilla. Haz tu pedido y recibe flores frescas en la puerta de tu casa.
            </p>
          </div>
        </div>
      </section>

      <section className="text-center">
        <p className="text-black-500 text-4xl mb-4">Contáctanos</p>
        <div className="flex justify-center gap-4 mb-8">
          <a href="#" className="inline-block">
            <WhatsappButton />
          </a>
          <a href="#" className="inline-block">
          <CorreoButton />
          </a>
        </div>
      </section>
    </div>
  );
}

export default Servicios;
